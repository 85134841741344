<template>
  <div>
    <div class="titleNav"> 
      <a-breadcrumb>
        <a-breadcrumb-item>您当前所在的位置：网站首页></a-breadcrumb-item>
        <a-breadcrumb-item><a href="">代叔信息></a></a-breadcrumb-item>
        <a-breadcrumb-item><a href="">出入仓></a></a-breadcrumb-item>
        <a-breadcrumb-item>入仓></a-breadcrumb-item>
        <a-breadcrumb-item class="songAdmin">送货管理</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="bodyIcon">
      <img class="successIcon" src="../../image/successIcon.png" alt="" srcset="">
      <div class="successText">
        下单成功！
      </div>
      <div class="xdSuccessText">
        恭喜您下单成功
      </div>
      <div class="submitSuccess">
        返回云仓
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "paySuccess",
  data() {
    return {};
  }
};
</script>
<style lang="less" scoped>
.titleNav {
  background-color: #eeeeee;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #999999;
  padding-bottom: 16px;
  .songAdmin {
    color: #fd4d02;
  }
}
.bodyIcon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .successIcon {
    width: 308px;
    height: 310px;
  }
  .successText {
    font-size: 40px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #fd4d02;
  }
  .xdSuccessText {
    font-size: 26px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #fd4d02;
    opacity: 0.9;
    margin-top: 20px;
  }
  .submitSuccess {
    width: 263px;
    height: 72px;
    background: #fd4d02;
    border-radius: 36px;
    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    line-height: 72px;
    margin-top: 110px;
    cursor: pointer;
  }
}
</style>

